#headerContiner {
  background-image: url("../../assests/topRightBackground.svg");
  background-repeat: no-repeat;
  background-position: right;
  height: 850px;
  overflow-x: hidden;
  display: flex;
  margin-top: 50px;
}

.image-rotator-container {
  position: relative;
  width: 450px;
  height: 500px;
  margin: auto;
  perspective: 500px;
  padding-left: 180px;
}

.image-container {
  position: absolute;
  top: 56%;
  transform: translate(-50%, -50%);
  transition: all 1s ease;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.front {
  transform: translate(-50%, -50%) scale(1);
  z-index: 3;
  opacity: 1;
}

.right {
  transform: translate(3%, -50%) scale(0.7);
  z-index: 2;
  opacity: 1;
}

.back {
  transform: translate(-50%, -50%) scale(0.6);
  z-index: 1;
  opacity: 0.2;
}

.left {
  transform: translate(-100%, -50%) scale(0.7);
  z-index: 2;
  opacity: 1;
}

@media (min-width: 1920px) {
  .image-rotator-container {
    width: 700px;
    height: 500px;
    margin: auto;
    perspective: 500px;
    padding-left: 300px;
  }
}

.headerLeftPane {
  flex: 4;
  text-align: left;
}
.headerRightPane {
  flex: 4;
}

#educationAppText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ff3a44;
}
#headerText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 50px;
  line-height: 80.02px;
  color: #343434;
}

#examhubText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 50px;
  line-height: 80.02px;
  background: linear-gradient(90deg, #6c3266, #004a8e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

#headerSubTitleText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
}

#SyllabusAreIncludedText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3b3b3b;
}

#userText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #004a8e;
}
#headerTextArea {
  padding-left: 130px;
}

.animal-image-header {
  position: absolute;
  bottom: 50px;
  left: 0;
  max-width: 150px;
  animation: moveLeftRight 2s ease-in-out infinite alternate;
}

@keyframes moveLeftRight {
  0% {
    left: -28%;
  }
  100% {
    left: 0%;
  }
}

.animal-image-header-2 {
  position: absolute;
  bottom: 250px;
  right: 0;
  max-width: 150px;
  animation: moveLeftRight2 2s ease-in-out infinite alternate;
}

@keyframes moveLeftRight2 {
  0% {
    right: -10%;
  }
  100% {
    right: 10%;
  }
}

@media (max-width: 678px) {
  #headerContiner {
    height: 100%;
    flex-direction: column;
  }
  #headerTextArea {
    margin-left: -120px;
  }

  .image-rotator-container {
    width: 350px;
    height: 250px;
    margin: auto;
    perspective: 500px;
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .animal-image-header {
    bottom: 0;
    animation: moveLeftRight 2s ease-in-out infinite alternate;
  }

  @keyframes moveLeftRight {
    0% {
      left: -10%;
    }
    100% {
      left: 10%;
    }
  }

  .animal-image-header-2 {
    top: 0;
    animation: moveLeftRight2 2s ease-in-out infinite alternate;
  }

  @keyframes moveLeftRight2 {
    0% {
      right: -18%;
    }
    100% {
      right: 10%;
    }
  }
}

@media (min-width: 1920px) {
  .animal-image-header {
    bottom: 20px;
    animation: moveLeftRight 2s ease-in-out infinite alternate;
  }

  @keyframes moveLeftRight {
    0% {
      left: 0;
    }
    100% {
      left: 10%;
    }
  }

  .animal-image-header-2 {
    top: 80px;
    animation: moveLeftRight2 2s ease-in-out infinite alternate;
  }

  @keyframes moveLeftRight2 {
    0% {
      right: 10%;
    }
    100% {
      right: 20%;
    }
  }
}
