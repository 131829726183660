#getStartText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 45px;
  line-height: 72.02px;
  color: #004a8e;
}

#getStartSubText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #505050;
}
