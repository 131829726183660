#applicationScreenshotsText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 40px;
  line-height: 64.02px;
  color: #004a8e;
}
#subTitleInApplication {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #343434;
}

.applicationSS-container {
  position: relative;
  width: 700px;
  height: 500px;
  margin: auto;
  perspective: 1200px;
  margin-top: 100px;
}

.applicationScreenshots-image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s ease;
}

.applicationScreenshots-image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.left-1 {
  transform: translate(-220%, -50%) scale(0.5);
  z-index: 1;
  opacity: 0.8;
}

.left-2 {
  transform: translate(-180%, -50%) scale(0.6);
  z-index: 2;
  opacity: 0.9;
}

.left-3 {
  transform: translate(-130%, -50%) scale(0.8);
  z-index: 3;
  opacity: 1;
}

.front {
  transform: translate(-50%, -50%) scale(1);
  z-index: 4;
  opacity: 1;
}

.right-1 {
  transform: translate(30%, -50%) scale(0.8);
  z-index: 3;
  opacity: 1;
}

.right-2 {
  transform: translate(80%, -50%) scale(0.6);
  z-index: 2;
  opacity: 0.9;
}

.right-3 {
  transform: translate(120%, -50%) scale(0.5);
  z-index: 1;
  opacity: 0.8;
}

@media (max-width: 678px) {
  .applicationSS-container {
    
    width: 200px;
    height: 300px;
    margin: auto;
    perspective: 500px;
    margin-top: 10px;
  }
}



.animal-container {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin-top: -120px;
}

.animal-image {
  position: absolute;
  top: -150px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 1.5s ease-in-out;
  max-width: 150px;
}

.animal-container.scrolled .animal-image {
  top: 0;
}
