.AwardContainer {
  margin-bottom: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: -100px;
}

.AwardHeaderText {
  font-family: "Hallowest";
  color: #004a8e;
  font-weight: 400;
  font-size: 28px;
  line-height: 36.4px;
}

.awardsImgScroll {
  margin-top: 30px;
  width: 100%;
  position: relative;
}

.scrollContent {
  display: flex;
  gap: 30px;
}

.awardFrame {
  border: 1px solid #cdcdcd;
  border-radius: 35px;
  box-shadow: 0px 2px 10px 2px #00000040;
  padding: 8px;
  width: 198px;
  flex-shrink: 0;
}

.awardName {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 18.2px;
  color: #292929;
}

.UnderLineSection {
  margin-top: 50px;
}

.AwardHeaderTextDiv {
  margin-bottom: 60px;
}

@media (max-width: 678px) {
  .AwardContainer {
    overflow: hidden;
    margin-top: 0;
  }
}
