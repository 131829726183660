#appFeatureContainer {
  background-image: url("../../assests/appFeaturesBackgroundPage.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 950px;
  margin-top: -50px;
  overflow-x: hidden;
}
#appFeatureText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 42px;
  line-height: 67.22px;
  color: #343434;
}

#RightPaneText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 40px;
  line-height: 63.99px;
  color: #004a8e;
  box-shadow: #00000040;
}

.appLeftPane {
  flex: 4;
  padding-top: 50px;
}

.appRightPane {
  flex: 4;
  padding-top: 50px;
}

#appSubText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  line-height: 22.5px;
  color: #3b3b3b;
}

#gameTitleText {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}

#gameSubTitleText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  line-height: 22.5px;
  color: #3b3b3b;
}

#SyllabusAvailableText {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00324c;
}
.animal-container-app {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin-top: 120px;
}

.animal-image-app {
  position: absolute;
  bottom: -150px;
  left: 22%;
  transform: translateX(-50%);
  transition: bottom 1.5s ease-in-out;
  max-width: 150px;
}

.animal-container-app.scrolled .animal-image-app {
  bottom: 0;
}

.Apptest {
  display: flex;
}

@media (max-width: 678px) {
  #appFeatureContainer {
    height: 100%;
    margin-bottom: 50px;
    margin-top: -230px;
  }
  #appFeature {
    flex-direction: column;
  }
  .animal-container-app {
    margin-top: 0;
  }
  .Apptest {
    flex-direction: column;
    align-items: center;
  }
  .Apptest div {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1920px) {
  .animal-image-app {
    bottom: -100px;
  }
}
