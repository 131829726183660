#testimonoialsContainer {
  background-image: url("../../assests/testimonialsBackground.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 950px;
  overflow-x: hidden;

  display: flex;
  margin-top: 50px;
}

.leftPane {
  flex: 3;
  padding-top: 250px;
  padding-left: 90px;
}

.rightPane {
  flex: 4;
}

#TestimonialsText {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #004a8e;
}

#HeaderText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 50px;
  line-height: 80px;
  color: #14183e;
}

#subtitleText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
}
#test_static {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #004a8e;
  text-align: center;
}

#test_reviews {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #004a8e;
  text-align: center;
}

.animatedImage {
  position: absolute;
  width: 600px;
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;
  backface-visibility: hidden;
}

.animatedImage.front {
  transform: translateY(0) scale(1);
  opacity: 1;
  z-index: 3;
}

.animatedImage.middle {
  transform: translateY(40px) translateX(50px) scale(0.95);
  opacity: 1;
  z-index: 2;
}

.animatedImage.back {
  transform: translateY(80px) translateX(90px) scale(0.9);
  opacity: 1;
  z-index: 1;
}

.description {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #5e6282;
  text-align: left;
}
.name {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #5e6282;
  text-align: left;
}
.location {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5e6282;
  text-align: left;
}

.profileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 5px;
}

.cardWrapper {
  perspective: 1000px;
  padding-top: 300px;
  padding-right: 700px;
}
.animatedImage {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
  margin: 10px;
  width: 65%;
  height: 280px;
}

@media (max-width: 678px) {
  #testimonoialsContainer {
    height: 100%;
    flex-direction: column;
    margin-bottom: 100px;
    overflow-y: hidden;
    margin-top: -100px;
  }
  .leftPane {
    padding-left: 10px;
    width: 100%;
  }
  .rightPane {
    width: 76%;
    padding: 0;
    margin-bottom: 400px;
    margin-top: 50px;
  }

  .cardWrapper {
    padding: 0;
    align-items: center;
  }

  .animatedImage {
    width: 100%;
    height: 350px;
  }
}
