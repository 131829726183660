.moble_column_4{
    flex: 2;
}
.moble_column_5{
    flex: 2;
}

.mobile_column_3_1{
    flex: 3;
}
.mobile-column_3_2{
    flex: 1;
}

.iconFooter{
    margin-top: 30px;
}