.wall-of-love {
  text-align: center;
  padding: 20px;
  font-family: "Arial", sans-serif;
  position: relative;
}

.wall-of-love h3 {
  font-size: 18px;
  color: #ff5a5f;
  font-family: "Poppins";
  font-weight: bold;
}

.wall-of-love h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: "Poppins";
}

.flip-display {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.flip-box {
  position: relative;
  width: 50px;
  height: 70px;
  background-color: black;
  color: white;
  font-size: 50px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  border-radius: 10px;
  font-family: "Poppins";
}

.flip-box::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #707070;
  z-index: 1;
}

.plus-icon {
  position: absolute;
  font-size: 33px;
  color: #4caf50;
  font-weight: 800;
  font-family: "Poppins";
  animation: slideUp 1s forwards, fadeOut 1s forwards;
  bottom: 100px;
  left: 70%;
  transform: translateX(-50%);
  opacity: 1;
  z-index: 10;
}

@keyframes slideUp {
  from {
    bottom: 100px;
  }
  to {
    bottom: 150px;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.trusted-label {
  margin-top: 10px;
  font-family: "Poppins";
  font-size: 16px;
  color: #5e6282;
  font-weight: bold;
  letter-spacing: 1px;
}

.regCount {
  margin-bottom: 80px;
}

.loader-count {
  width: fit-content;
  font-weight: 600;
  font-family: monospace;
  font-size: 20px;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader-count:before {
  content: "Loading...";
}
@keyframes l4 {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

@media (max-width: 678px) {
  .plus-icon {
    left: 99%;
  }
}
