#list {
  gap: 45px;
}

#downloadApp {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #004a8e;
  padding-left: 10px;
  padding-right: 10px;
}

#downloadApp_a {
  border: 1px solid #004a8e;
  border-radius: 20px;
  width: auto;
  height: 34px;
  padding: 3px 10px;
}

#dropdown-basic {
  color: black;
  background-color: #ffffff5a;
  border: none;
}

#navName {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #343434;
}

#backToText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 18.2px;
  color: #059dff;
}

.scroll-to-top {
  position: fixed;
  bottom: 25px;
  right: 35px;
  z-index: 1000;
  display: inline-block;
  height: 50px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.scroll-to-top:hover {
  opacity: 1;
}

#fedesLogoDiv {
  margin-top: 20px;
  margin-left: 130px;
}

@media (max-width: 678px) {
  #list {
    gap: 10px;
  }
  #fedesLogoDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-top: 20px;
  }

  #Logo {
    max-width: 100%;
  }
}
