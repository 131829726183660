#staticsBundle {
  flex-direction: column;
}
#staticsValue {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #004a8e;
}
#staticText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #004a8e;
}
#downloadValue {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  color: #004a8e;
}

.vertical-line1 {
  border-left: 2px solid #004a8e99;
  height: 100px;
  margin-top: -120px;
  position: absolute;
}

.vertical-line2 {
  border-left: 2px solid #004a8e99;
  height: 100px;
  margin-top: -80px;
  position: absolute;
}

.staticMain {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}
.static_1 {
  flex: 3;
}
.static_2 {
  flex: 3;
}
.static_3 {
  flex: 3;
}
.animal-container-statics {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin-top: 120px;
}

.animal-image-statics {
  position: absolute;
  bottom: -150px;
  left: 76%;
  transform: translateX(-50%);
  transition: bottom 1.5s ease-in-out;
  max-width: 150px;
}

.animal-container-statics.scrolled .animal-image-statics {
  bottom: 0;
}

@media (max-width: 678px) {
  #staticsValue {
    font-size: 25px;
  }
  #downloadValue {
    font-size: 28px;
  }
  .animal-image-statics {
    display: none;
  }
  .vertical-line2 {
    display: none;
  }
  .vertical-line1 {
    display: none;
  }
  .staticMain {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
