#footerContainer {
  background-image: url("../../assests/gradientBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  overflow-x: hidden;
}

.footerRow_1 {
  display: flex;
}
.footerRow {
  border: 2px solid #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 16px;
  padding-right: 50px;
  background-color: #ffffff;
}

.column_1 {
  flex: 4;
}
.column_2 {
  flex: 2;
}
.column_3 {
  flex: 2;
}
.column_4 {
  flex: 3;
}
.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}
#contactUsBtn {
  background-color: #004a8e;
  border-radius: 8px;
}
.footerRow_2 {
  display: flex;
  margin-top: 20px;
}
.column_2_1 {
  flex: 3;
}
.column_2_2 {
  flex: 3;
}
.column_2_3 {
  flex: 3;
}
.footerRow_3 {
  display: flex;
  margin-top: 40px;
}
.column_3_1 {
  flex: 1;
}
.column_3_2 {
  flex: 1;
}
#footerText {
  font-family: "Inter";
  font-weight: 800;
  font-size: 40px;
  line-height: 40px;
  color: #ffffff;
}
#mailText {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: #ffffff;
}
#footerHeading {
  font-family: "Inter";
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #1b1819;
}
#List {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: #1b1819;
  margin: 5px;
  padding: 0;
  text-decoration:none
}
#contactUsText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 15.6px;
  color: #1b1819;
}

#proudlyText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 15.6px;
  color: #1b1819;
}

#textAreaDiv {
  border: 1px solid #ffffff99;
  width: 100%;
  border-radius: 12px;
  margin-top: 20px;
}
#textSection {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

#formText {
  font-family: "Inter";
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
}

.social-icon {
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

.social-icon:hover {
  filter: grayscale(0);
}

#footerLink{
  text-decoration: none;
}

@media (max-width: 678px) {
  .footerRow_3 {
    flex-direction: column;
  }
  #footerContainer {
    height: 100%;
  }
  #footerText {
    font-size: 27px;
  }
}
