#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-in-out;
  z-index: 9999;
}

#preloader.hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.loader {
  --r1: 154%;
  --r2: 68.5%;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(
      var(--r1) var(--r2) at top,
      #0000 79.5%,
      #269af2 80%
    ),
    radial-gradient(var(--r1) var(--r2) at bottom, #269af2 79.5%, #0000 80%),
    radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%), #ccc;
  background-size: 50.5% 220%;
  background-position: -100% 0%, 0% 0%, 100% 0%;
  background-repeat: no-repeat;
  animation: l9 2s infinite linear;
}
@keyframes l9 {
  33% {
    background-position: 0% 33%, 100% 33%, 200% 33%;
  }
  66% {
    background-position: -100% 66%, 0% 66%, 100% 66%;
  }
  100% {
    background-position: 0% 100%, 100% 100%, 200% 100%;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.preloader-text {
  margin-top: 40px;
  color: #3498db;
  font-size: 3rem;
  font-family: "Hallowest";
  font-weight: bold;
  background: linear-gradient(90deg, #4caf50, #708db6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-top: 10px;
  opacity: 0;
  animation: textSlideIn 2s ease forwards 0.5s;
  position: relative;
}

@keyframes textSlideIn {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.preloader-logo {
  width: 200px;
  height: auto;
  margin-top: 20px;
}

.mobile-footer {
  display: none;
}

@media (max-width: 768px) {
  .mobile-footer {
    display: block;
  }

  .desktop-footer {
    display: none;
  }
}
